<template>
  <div class="site-cont">
    <div class="search-store-title">{{info.name}}</div>
    <ul class="result-cnt">
      <li>
        <div class="list-title">品牌</div>
        <p>{{info.brand}}</p>
      </li>
      <li>
        <div class="list-title">地址</div>
        <p>{{info.address}}</p>
      </li>
      <li>
        <div class="list-title">联系方式</div>
        <p>{{info.mobile}}</p>
      </li>
    </ul>
    <div class="map-title icon-flex" @click="goAmap">
      <span>
        查看地图
      </span>
      <right theme="outline" fill="#333" :strokeWidth="4"/>
    </div>
    <div class="map">
      <baidu-map v-if="position.lng"
        :center="{lng: position.lng, lat: position.lat}"
        :zoom="15"
        class="bm-view" >
        <bm-marker :position="{lng: position.lng, lat: position.lat}" 
          :dragging="true" 
          animation="BMAP_ANIMATION_BOUNCE">
        </bm-marker>
      </baidu-map>
    </div>
  </div>
</template>

<script>
import {Right} from '@icon-park/vue'


export default {
  components: {
    Right
  },
  data () {
    return {
      info: {},
      position: {
        lng: '',
        lat: '',
      }
    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    goAmap () {
      let obj = this.$utils.bd_decrypt(this.position.lng, this.position.lat);
      let centerStr = obj.lng + ',' + obj.lat;
      window.location.href = 'https://uri.amap.com/marker?position='+centerStr +'&name='+ this.info.name
    },
    /**
     * 获取门店详情
     */
    getInfo () {
      const vm = this;
      let postData = {
        id: this.$route.params.id
      }
      vm.$store.commit('updateLoader', true);
      this.$axios({
        method: 'post',
        baseURL: this.$init.apis.default,
        url: "/dic/maintenance/queryMaintenanceById",
        data: postData,
        timeout: 600000,
      }).then(function(response) {
        vm.$store.commit('updateLoader', false);
        vm.info = response.data.body;
        vm.position = {
          lng: vm.info.longitude,
          lat: vm.info.dimension,
        }
      })
      .catch(function() {
        vm.$store.commit('updateLoader', false);
        vm.$createToast({
          txt: '网络错误',
          type: 'error'
        }).show()
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.bm-view{
  height: 100%;
  width: 100%;
}
.site-cont{
  min-height: 100.1vh;
  box-sizing: border-box;
  padding: 80px 0;
}
.search-store-title{
  font-size: 64px;
  line-height: 88px;
  color: #000;
  padding: 0 32px;
}
.result-cnt{
  padding: 64px 32px 0;
  li{
    padding-bottom: 80px;
  }
  p{
    font-size: 32px;
    color: rgba(0,0,0,0.5);
    line-height: 48px;
    padding-top: 32px;
  }
}
.list-title{
  font-size: 40px;
  color: #000;
  line-height: 64px;
  font-weight: bolder;
}
.map-title{
  padding: 20px 32px;
  font-size: 28px;
  font-weight: bold;
  span{
    font-size: 28px;
    font-weight: bold;
  }
}
.map{
  width: 100%;
  height: 100vw;
  background-color: #eee;
}
</style>
